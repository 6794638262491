import React from "react";
import CeWrapper from "../../global/CeWrapper";
import ContentLibs from "../../../libs/ContentLibs";
import ContainerRow from "../../global/ContainerRow";
import ContainerColumn from "../../global/ContainerColumn";
import Components from "../../index";

const ce = ({ ceData }) => {
  const layoutMappings = {
    100: "bg-color-gray",
  };

  let contentElementsColumn1 = [];
  let contentElementsColumn2 = [];

  if (ceData.contentParts.column_0?.contents) {
    contentElementsColumn1 = ContentLibs.unifyContentArray(
      ceData.contentParts.column_0.contents
    );
  }

  if (ceData.contentParts.column_1?.contents) {
    contentElementsColumn2 = ContentLibs.unifyContentArray(
      ceData.contentParts.column_1.contents
    );
  }

  const enableDefaultGridWrap = ceData.properties?.frameClass === "fluid";

  return (
    <CeWrapper
      ceData={ceData}
      additionalClassName="ce--container"
      enableDefaultGridWrap={enableDefaultGridWrap}
      layoutMapping={layoutMappings}
    >
      <div className={"ce__container-content"}>
        <ContainerRow>
          <ContainerColumn className={"medium-6"}>
            {contentElementsColumn1 &&
              contentElementsColumn1.map((contentItem, i) =>
                Components(contentItem, i)
              )}
          </ContainerColumn>
          <ContainerColumn className={"medium-6"}>
            {contentElementsColumn2 &&
              contentElementsColumn2.map((contentItem, i) =>
                Components(contentItem, i)
              )}
          </ContainerColumn>
        </ContainerRow>
      </div>
    </CeWrapper>
  );
};

export default ce;
